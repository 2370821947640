import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const Articles = ({ articles }) => {
  console.log("articles", articles)
  return (
    <section>
      {articles.map(article => {
        return (
          <article>
            <span class="font-heading">
              <Link
                style={{ boxShadow: `none` }}
                to={article.node.frontmatter.path}
              >
                {article.node.frontmatter.title}
              </Link>
            </span>
            <div>{article.node.frontmatter.description}</div>
            <small>{article.node.frontmatter.date}</small>
          </article>
        )
      })}
    </section>
  )
}

Articles.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
}

Articles.defaultProps = {
  articles: [],
}

export default Articles
