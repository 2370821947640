import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Articles from "../components/articles"
import useArticles from "../hooks/useArticles"

const ArticlesPage = () => {
  const data = useArticles()
  console.log("articles", data)
  return (
    <Layout>
      <SEO title="Articles" />
      <Articles articles={data}></Articles>
    </Layout>
  )
}

export default ArticlesPage
