import { useStaticQuery, graphql } from "gatsby"

export const useArticles = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              description
              path
              title
            }
            id
          }
        }
      }
    }
  `)

  return allMarkdownRemark.edges
}

export default useArticles
